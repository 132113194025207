import { asIs, throwError } from "@dancrumb/fpish";
import { StatusCodes } from "http-status-codes";
import posthogJs from "posthog-js";
import { StudioEventTypes } from "sutro-analytics";
import { getUlid, isEmpty } from "sutro-common";
import { AuthData } from "sutro-common/auth-data";
import { UTM_COOKIE_ID } from "sutro-common/cookie-ids";
import { addPrefixToKeys } from "sutro-common/object-operations";
import { StudioUserProfile } from "sutro-common/sutro-data-store-types";
import { create } from "zustand";

import { StudioError } from "~/lib/studio-error";
import { SutroApi } from "~/lib/sutro-api/index";

import { setCookie } from "./cookies";
import { isEmailValid } from "./is-email-valid";
import { testPassword } from "./is-password-valid";
import {
  clearFromLocalStorage,
  retrieveFromLocalStorage,
  retrieveHasUserVisitedStudio,
  retrievePosthogIdentityAlias,
  storeHasUserVisitedStudio,
  storeInLocalStorage,
  storePosthogIdentityAlias,
} from "./studio-local-storage";
import { isStudioHttpError } from "./sutro-api/StudioHttpError";

type ProfileUpdateOutcome = {
  success: boolean;
  error?: string;
};

type ResetPasswordArgs = {
  newPassword: string;
  confirmNewPassword: string;
  token: string;
};

export type Profile = {
  currentUser: StudioUserProfile | null;
  setCurrentUser: (newUser: StudioUserProfile | null) => void;
  refreshUser: () => void;
  loadingUserDetails: boolean;
  register: (email: string, password: string) => Promise<StudioUserProfile>;
  login: (email: string, password: string) => Promise<StudioUserProfile>;
  hasUserVisitedStudio: boolean;
  setHasUserVisitedStudio: (hasVisited: boolean) => void;
  changePassword: (password: string, newPassword: string) => Promise<AuthData>;
  requestResetPassword: (email: string) => Promise<ProfileUpdateOutcome>;
  resetPassword: (args: ResetPasswordArgs) => Promise<ProfileUpdateOutcome>;
  logout: (redirect?: () => void) => void;
  /**
   * Some events need to be deferred until login is complete.
   *
   * This function allows us to do that
   *
   * It assumes that the event is a CustomEvent and that the provided `detail` is appropriate for the provided eventName
   */
  deferEvent: (eventName: string, detail?: unknown) => void;
  deferredEvents: ReadonlyArray<[eventName: string, detail?: unknown]>;
  posthogIdentityAlias: string;
  isGetUserError: boolean;
};

type PersistedUser = Pick<StudioUserProfile, "id" | "identity">;

const retrievePersistedUser = () =>
  retrieveFromLocalStorage<PersistedUser>("sutro:studio-user");
const storeStudioUser = (studioUser: PersistedUser) =>
  storeInLocalStorage(studioUser, "sutro:studio-user");
const clearStudioUser = () => clearFromLocalStorage("sutro:studio-user");

/**
 * This function just sends an event that was previously deferred
 */
const dispatchDeferredEvent = (event: string, detail?: unknown) => {
  window.dispatchEvent(new CustomEvent(event, detail ? { detail } : undefined));
};

const _requestIdleCallback = (callback: () => void, timeout: number) => {
  if (typeof requestIdleCallback !== "undefined") {
    requestIdleCallback(callback, { timeout });
  } else {
    setTimeout(callback, timeout);
  }
};

export const useProfile = create<Profile>()((set, get) => {
  /**
   * Sends a request to the server to get the details of the current user
   */
  const loadCurrentUserDetails = async () => {
    set({ isGetUserError: false });
    await SutroApi.getApi()
      .authenticate()
      .get<StudioUserProfile>("/users/current")
      .then((result) => {
        if (result.isRight()) {
          const user = result.getRight();
          get().setCurrentUser(user);
          set({ loadingUserDetails: false });
        } else {
          const error = result.getLeft();
          if (
            isStudioHttpError(error, [
              StatusCodes.UNAUTHORIZED,
              StatusCodes.NOT_FOUND,
            ])
          ) {
            set({ currentUser: null, loadingUserDetails: false });
            window.dispatchEvent(new CustomEvent("sutro:force-logout"));
          } else {
            set({ isGetUserError: true });
          }
        }
      });
  };

  const persistedUser = retrievePersistedUser();
  let loadingUserDetails = true;

  if (persistedUser !== null) {
    void loadCurrentUserDetails();
  } else {
    loadingUserDetails = false;
  }

  // Initialize posthog first
  let posthogIdentityAlias = retrievePosthogIdentityAlias()?.id;
  if (persistedUser?.id !== undefined) {
    posthogIdentityAlias = persistedUser.id;
  } else if (posthogIdentityAlias === undefined) {
    posthogIdentityAlias = getUlid();
  }
  storePosthogIdentityAlias(posthogIdentityAlias);

  /*
   * Sets the current user in this Store and sends the appropriate events
   */
  const setCurrentUser = (newUser: StudioUserProfile | null) => {
    window.dispatchEvent(new CustomEvent("userChanged", { detail: newUser }));
    if (newUser) {
      storeStudioUser(newUser);

      posthogJs.alias(newUser.id, get().posthogIdentityAlias);
      posthogJs.identify(newUser.id, {
        username: newUser.identity,
        ...addPrefixToKeys("user.", newUser),
      });
      set({ posthogIdentityAlias: newUser.id });
      posthogJs.register({ email: newUser.identity });
    } else {
      clearStudioUser();
      posthogJs.unregister("email");
      posthogJs.reset();
      set({ posthogIdentityAlias: getUlid() });
      posthogJs.identify(get().posthogIdentityAlias);
    }
    storePosthogIdentityAlias(get().posthogIdentityAlias);

    set({ currentUser: newUser });
    // We hold off on replaying the deferred events until the next idle period
    // so that the event handlers have an up-to-date view of the app state
    _requestIdleCallback(() => {
      if (newUser !== undefined) {
        get().deferredEvents.forEach(([eventName, detail]) =>
          dispatchDeferredEvent(eventName, detail)
        );
        set({ deferredEvents: [] });
      }
    }, 250);
  };

  /**
   * Registers a new user with the provider credentials
   */
  const register = async (
    email: string,
    password: string
  ): Promise<StudioUserProfile> => {
    if (!isEmailValid(email)) {
      throw new StudioError("Invalid email format");
    }
    const passwordTestResults = testPassword(password);
    if (!isEmpty(passwordTestResults)) {
      throw new StudioError("Password doesn't meet requirements", {
        context: { passwordTestResults },
      });
    }

    const result = await SutroApi.getApi().post<StudioUserProfile>("/users", {
      identity: email,
      password,
    });
    return result.map(throwError, asIs);
  };

  /**
   * Logs a user in with the provided credentials
   */
  const login = async (
    email: string,
    password: string
  ): Promise<StudioUserProfile> => {
    if (!isEmailValid(email)) {
      throw new StudioError("Invalid email format");
    }
    const result = await SutroApi.getApi().post<{
      user: StudioUserProfile;
      auth: AuthData;
    }>("/users/token", {
      identity: email,
      password,
    });
    return result.map(throwError, ({ user, auth }) => {
      get().setCurrentUser(user);
      SutroApi.updateAuthData(auth);
      window.dispatchEvent(
        new CustomEvent("userChanged", {
          detail: user,
        })
      );
      return user;
    });
  };

  /**
   * Changes the password for an authenticated user
   */
  const changePassword = async (
    currentPassword: string,
    newPassword: string
  ): Promise<AuthData> => {
    const passwordTestResults = testPassword(newPassword);
    if (!isEmpty(passwordTestResults)) {
      throw new StudioError("Password doesn't meet requirements", {
        context: { passwordTestResults },
      });
    }
    if (currentPassword === newPassword) {
      throw new StudioError("New Password cannot match current password");
    }
    const result = await SutroApi.getApi()
      .authenticate()
      .post<AuthData>(`/users/password`, {
        currentPassword,
        newPassword,
      });
    return result.map(
      (e) => {
        if (isStudioHttpError(e, StatusCodes.FORBIDDEN)) {
          throw new StudioError(
            "Please make sure that you entered your current password correctly"
          );
        }
        throw e;
      },
      (auth) => {
        SutroApi.updateAuthData(auth);
        return auth;
      }
    );
  };

  /**
   * Requests a "reset password" email to be sent to the user with the provided email
   */
  const requestResetPassword = async (
    email: string
  ): Promise<ProfileUpdateOutcome> => {
    if (!isEmailValid(email)) {
      throw new StudioError("Invalid email format");
    }
    const result = await SutroApi.getApi().post("/users/passwordReset", {
      email,
    });
    return result.map(throwError, () => ({
      success: true,
    }));
  };

  /**
   * Resets the password for a user with the provided token
   */
  const resetPassword = async ({
    newPassword,
    confirmNewPassword,
    token,
  }: {
    newPassword: string;
    confirmNewPassword: string;
    token: string;
  }): Promise<ProfileUpdateOutcome> => {
    if (newPassword !== confirmNewPassword) {
      return Promise.resolve({
        success: false,
        error: "Passwords do not match",
      });
    }

    const result = await SutroApi.getApi().post<AuthData>(
      `/users/current/password`,
      {
        newPassword,
        resetPasswordToken: token,
      }
    );
    return result.map<ProfileUpdateOutcome>(
      (e) => {
        return { success: false, error: e.message };
      },
      (authData) => {
        SutroApi.updateAuthData(authData);
        return { success: true };
      }
    );
  };

  /**
   * Logs the user out
   */
  const logout = (redirect?: () => void) => {
    posthogJs.capture(StudioEventTypes.USER_LOGOUT);
    // Clearing these on logout is mostly defensive; we don't want the UTM data
    // to be associated with someone logging on in the same session of a shared device
    setCookie(UTM_COOKIE_ID, null);
    SutroApi.clearAuthData();
    clearStudioUser();
    const currentUser = get();
    window.dispatchEvent(
      new CustomEvent("userLoggedOut", { detail: currentUser })
    );
    get().setCurrentUser(null);

    if (redirect) {
      redirect();
    } else {
      // we do a reload to clear all local state
      window.location.reload();
    }
  };

  return {
    loadingUserDetails,
    posthogIdentityAlias,
    currentUser: null,
    refreshUser: loadCurrentUserDetails,
    setCurrentUser,
    hasUserVisitedStudio: retrieveHasUserVisitedStudio(),
    setHasUserVisitedStudio: (newValue: boolean) => {
      storeHasUserVisitedStudio(newValue);
      set({ hasUserVisitedStudio: newValue });
    },
    register,
    login,
    changePassword,
    requestResetPassword,
    resetPassword,
    logout,

    deferredEvents: [],
    deferEvent: (eventName: string, detail?: unknown) => {
      if (get().currentUser) {
        dispatchDeferredEvent(eventName, detail ? { detail } : undefined);
      } else {
        set((state) => ({
          deferredEvents: [...state.deferredEvents, [eventName, detail]],
        }));
      }
    },
    isGetUserError: false,
  };
});
