import config from "~/app.config";

type CookieOptions = {
  daysForExpiration?: number;
  domain?: string;
  secure?: boolean;
  path?: string;
  sameSite?: "Strict" | "Lax" | "None";
};
export function setCookie(
  key: string,
  value: string | object | undefined | null,
  {
    daysForExpiration,
    domain = config.cookieDomain,
    secure = true,
    path = "/",
    sameSite,
  }: CookieOptions = {}
) {
  if (value === undefined || value === null) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    return;
  }
  if (typeof value === "object") {
    value = `j:${JSON.stringify(value)}`;
  }
  const cookieDetails: {
    expires?: string;
    path: string;
    SameSite?: "Strict" | "Lax" | "None";
    domain: string;
    secure: boolean;
  } = {
    path,
    secure,
    domain,
    SameSite: sameSite,
  };

  if (daysForExpiration) {
    const date = new Date();
    date.setTime(date.getTime() + daysForExpiration * 24 * 60 * 60 * 1000);
    cookieDetails.expires = date.toUTCString();
  }

  const newCookie = Object.entries({
    // We have to encode the value because it could contain characters that are not allowed in a cookie.
    [key]: encodeURIComponent(value),
    ...cookieDetails,
  })
    .map(([cookieKey, cookieValue]) => {
      if (cookieValue === undefined) {
        return null;
      }
      if (cookieKey === "secure") {
        if (cookieValue) {
          return "Secure";
        } else {
          return null;
        }
      } else {
        return `${cookieKey}=${cookieValue}`;
      }
    })
    .filter((s) => s !== null)
    .join("; ");

  document.cookie = newCookie;
}

export function getCookie(key: string): string | null {
  const cookieValues = document.cookie.split(";").map((s) => s.trim());
  for (const value of cookieValues) {
    /**
     * Starts with the word `key`.
     *
     * e.g. key = 'myKey'.
     * myKey=foo;    // match
     * myKeyBar=baz; // not a match
     * barmyKey=baz; // not a match
     */
    const rg = new RegExp(`^${key}\\b`);

    if (value.match(rg)) {
      const keyVal = value.split("=");
      if (keyVal.length === 2) {
        return keyVal[1].trim();
      }
    }
  }
  return null;
}
